<template>
  <div class="templates">
    <div class="templates__header">
      <div id="templates-header" class="templates__left-side"></div>

      <div class="templates__right-side">
        <CAction :actions="topActions" ignore-active-project-id />
      </div>
    </div>

    <router-view />
  </div>
</template>

<script>
import CAction from '@/components/CAction'
import { computed } from 'vue'

export default {
  name: 'Index',
  components: { CAction },
  provide() {
    return {
      mountedComponent: computed(() => this.mountedComponent),
    }
  },
  data() {
    return {
      mountedComponent: false,
      topActions: [],
    }
  },
  mounted() {
    this.mountedComponent = true
    this.$EventBus.on('updateTopActions', this.updateTopActions)
  },
  unmounted() {
    this.$EventBus.off('updateTopActions', this.updateTopActions)
  },
  methods: {
    updateTopActions(actionsList) {
      this.topActions = actionsList
    },
  },
}
</script>

<style lang="scss" scoped>
.templates {
  padding-bottom: 20px;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }
}
</style>
